

window.pgAsyncInit = function () {
  // Get the AppId
  scriptElmt = document.getElementById('PGLogin')

  console.log('PG SSO Loaded 2')

  PG.init({
    appId: scriptElmt.dataset.appid,
  })


}
